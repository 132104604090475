import * as React from "react"
import { Link } from "gatsby"

export const PhilosophyContent = () => {
  return (
    <>
      <div className="container pageBodyBox">
        <section id="description">
          <p>
            昔から創作をしてきて、また長らく仕事でデザイナーしています。そのため、今までも友人や仕事仲間にデザインの相談をされることがありました。<br />
            私がすべてを手がけなくても、気になっているところを伺いアドバイスをすることで、より気に入ったデザインができたり、できなかったことができるようになって、お礼を言われるのがとてもうれしかったです。
          </p>
          <p>
            ものづくりをすることはもちろん、ものづくりをしている人が大好きで、心から尊敬しています。<br />
            「トナリノ」は、利用者の方にとって隣にいるくらい身近で、力になれるサービスでありたいという気持ちで名付けました。<br />
            このサービスを立ち上げることで、よりもっと多くの方とお話して、力になれたらいいなと思っています。
          </p>
          <p className="mb-5">
            体の不調でマッサージに行ったり、気分を上げるためにネイルサロンに行ったり……それくらいの気軽な気分で、デザインについて相談できるサービスになれたらうれしいです。
          </p>
        </section>
        <section id="advisor">
          <h2 className="fs-2 fw-bold mb-3 mt-5 text-center">相談相手 </h2>
          <p className="mb-1 text-center">nao</p>
          <div className="text-center">
            <img
              src="/images/icon_nao.png"
              alt="about"
              height={100}
              placeholder="none"
              className="mt-2 mb-3 rounded-pill"
            />
          </div>
          <p className="text-center">
            <a
              href="https://naoi109.github.io/"
              className="btn btn-outline-primary rounded-pill btn-fixed-width py-3"
              target="_blank"
              rel="noreferrer"
            >
              ポートフォリオサイト
            </a>
          </p>
        </section>
        <section id="advisorDescription">
          <p>
            広告代理店でのWebデザイナー、ゲーム事業会社でのUI設計・フロントエンド実装経験を経て、現在はフリーランスとして、UXデザイン（利用者目線で価値提供を行うための情報整理を行う）やUIデザイン（利用者に価値を伝えるためのインターフェースをつくる）を専門としています。
          </p>
          <p className="mb-0">
            Webサービスのデザインを得意としていますが、アプリデザイン・ゲームUIなどの制作経験もあります。このサイトのデザイン・マークアップも自身で行っています。
          </p>
          <p class="mb-5">
            仕事をしながら自分でも同人誌をつくり、コミックシティなどのイベントに長らく個人参加をしています。
          </p>
        </section>
        <section id="skills">
          <h3 className="fs-4 fw-bold mb-2">スキル範囲・できること</h3>
          <ul>
            <li className="mb-0">利用者目線に立ったUXおよびUIデザイン</li>
            <li className="mb-0">バナー等の画像加工、名刺・広告等の紙デザイン</li>
            <li className="mb-0">Webサービスのコンポーネントデザイン、ガイドライン策定</li>
            <li className="mb-0">
              Bootstrap等フロントエンドフレームワークを使ったWebコーディング
            </li>
            <li className="mb-0">React等JavaScriptライブラリを使ったテンプレートコーディング</li>
            <li className="mb-0">GitHubを使ったタスク管理および運用</li>
            <li className="mb-0">
              同人誌の表紙デザイン・本文の仕様や文字組み等の装丁、各同人誌印刷所の得意な手法などの案内
            </li>
          </ul>
          <p>
            こういうサービスを運営しながら、デザインについて迷っている人の課題やニーズをもっともっと探っていきたいです。
          </p>
          <p>
            私自身に興味を持ってくださったり、一緒にお仕事をしたい、もしくはなんらかの理由でコンタクトを取りたいと思ってくださる方は、お手数ですがこのサイトのお問い合わせもしくはポートフォリオサイトのお問い合わせよりご連絡いただけたら幸いです。
          </p>
        </section>
        <p className="mt-4 mb-5 text-center">
          <Link to="https://tayori.com/form/bce4102be00442293ae9c3fab28640c8eef48ea5/" className="btn btn-primary btn-fixed-width rounded-pill py-3">
            お問い合わせ
          </Link>
        </p>
        <section id="thanks">
          <h2 className="fs-4 fw-bold mb-3 mt-5">ご協力いただいた方</h2>
          <h3 className="fs-5 fw-bold mb-2">トップページの動画モーション制作</h3>
          <p className="mb-3">川田 陽亮さん（ササノハ）</p>
          <h3 className="fs-5 fw-bold mb-2">サイトの構築・コーディング協力</h3>
          <p className="mb-3">荒木 健さん</p>
          <h3 className="fs-5 fw-bold mb-2">利用規約・プライバシーポリシーの作成</h3>
          <p className="mb-5">行政書士柴秀雄事務所様</p>
        </section>
      </div>
    </>
  )
}
