import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"
import { PhilosophyContent } from "../components/philosophy/PhilosophyContent"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="サービス理念,トナリノ,デザイン,デザイン相談"
      description="トナリノを立ち上げたきっかけやサービス運営者についてをご覧いただけます。"
      ogTitle="サービス理念・私について"
      ogDescription="トナリノの理念や、サービス運営者について"
      ogUrl="https://tonarino.co/philosophy"
    />
    <PageTitleBox title="サービス理念・私について" />
    <section id="philosophyContent">
      <PhilosophyContent />
    </section>
  </Layout>
)

export default IndexPage
